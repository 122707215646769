import { useMutation } from "react-query"
import ApiEndpoints, { API_END_POINT, ROUTES } from "../apiClient/apiEndpoints"
import { useLocalStorage } from "usehooks-ts"
import { useRouter } from "next/navigation"
import { apiClient } from "@/apiClient/apiService"
import { UserDetails } from "@/interfaces/UserDetails"
import { toast } from "react-toastify"

interface IFormData {
  email_id: string
  password: string
}
interface ILoginResponse {
  token: string
  data: UserDetails["data"]
}
interface UseLoginMutationProps {
  onError?: (error: string) => void // Callback function for handling errors
}

const useLoginMutation = (props?: UseLoginMutationProps) => {
  const [_, setAccessToken] = useLocalStorage("accessToken", "")
  const router = useRouter()

  const getUserDetailsMutation = useMutation({
    mutationFn: (data) => {
      return apiClient.get(`${API_END_POINT.GET[ROUTES.getUserDetails]}`)
    },
  })

  return useMutation({
    mutationFn: (data: IFormData) => {
      return apiClient.post<IFormData, ILoginResponse>(API_END_POINT.POST[ROUTES.login], data)
    },
    onSuccess: (data) => {
      if (data?.token) {
        setAccessToken(data.token)
        apiClient.setAccessToken(data.token)

        getUserDetailsMutation.mutate(undefined, {
          onSuccess: (data: any) => {
            toast.success("Login Successful!", { position: "top-right" })
            let isCleaner = data?.data?.service_type === "Cleaning"
            if (isCleaner) {
              router.push("/cleans")
            } else {
              router.push("/maintenance")
            }
          },
        })
      }
    },
    onError: (error) => {
      const errorMessage = "Invalid User" // Default error message
      // toast.error(errorMessage);

      // Check if the onError callback is provided
      if (props?.onError) {
        props.onError(errorMessage)
      }
    },
  })
  ;``
}

export default useLoginMutation
