//@ts-nocheck
"use client"
import useLoginMutation from "@/hooks/useLoginMutation"
import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { ToastContainer } from "react-toastify"
import { zodResolver } from "@hookform/resolvers/zod"
import SergoLogoWithPunchs from "@/components/assets/SergoLogoWithPunchs"
import { useState, MouseEvent, useEffect } from "react"
// ** Icons Imports
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import AppStore from "@/app/store/AppStore"

const formSchema = z.object({
  email_id: z.string().email("Invalid email").min(1, "Email is required"),
  password: z.string().min(1, "Password is required").min(3, "Password must have more than 3 characters"),
})
type FormSchemaType = z.infer<typeof formSchema>

const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    AppStore.clearStoredData()
  }, [])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormSchemaType>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
  })
  const {
    mutateAsync: login,
    isLoading,
    isError,
    error,
  } = useLoginMutation({
    onError: (errorMessage) => {},
    onSuccess: () => {
      reset()
    },
  })
  const onLoginSubmit = async (formData: FormSchemaType) => {
    await login(formData)
  }

  return (
    <>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "grey",
            width: "40%",
            backdropFilter: "blur(24.55px)",
            background: "rgba(255, 255, 255, 0.1)",
            border: "1.40286px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <Grid item mt={"10rem"}>
            <SergoLogoWithPunchs />
          </Grid>
          <Box width="40%" mt={10}>
            <form noValidate autoComplete="off">
              {isError && (
                <Typography color={"red"} textAlign={"center"} marginY={2} fontSize={19}>
                  {error?.response?.data?.message}
                </Typography>
              )}
              <Box display="flex" flexDirection="column">
                <TextField
                  autoFocus
                  label="Enter Email Address"
                  variant={"standard"}
                  placeholder="Enter Email Address"
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  {...register("email_id")}
                />
                {errors.email_id && (
                  <Typography m={0} color={"red"}>
                    Email Id is required
                  </Typography>
                )}

                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoFocus
                        label="Enter Password"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        variant={"standard"}
                        error={Boolean(errors.password)}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                aria-label="toggle password visibility"
                              >
                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.password && <FormHelperText sx={{ color: "error.main", ml: 0 }}>{errors.password.message}</FormHelperText>}
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  size="large"
                  type="submit"
                  variant="outlined"
                  color="success"
                  disabled={isLoading}
                  onClick={handleSubmit(onLoginSubmit)}
                  sx={{
                    mb: 15,
                    mt: 10,
                    width: "100px",
                    height: "50px",
                    border: "2px solid #44D400",
                    cursor: "pointer",

                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "transparent",
                      border: "2px solid #44D400",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress sx={{ color: "#1b920a" }} />
                  ) : (
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.2rem",
                        color: "#44D400",
                      }}
                    >
                      Login
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default LoginScreen
